<template>
  <div class="result-box">
    <img class="img-result" src="@/assets/images/unknow.png" width="134" />
    <div class="status">
      {{ $t('message.result.error') }}
    </div>
  </div>
</template>
<script>
import { getBrandInfo } from '@/api/checkout';
import { sendGaResultEvent } from '@/utils/tools/ga';

export default {
  created() {
    getBrandInfo(this.$route.query.key).then((res) => {
      if (res.respCode === '20000' && res.data) {
        this.$store.dispatch('app/setBrandInfo', res.data);
      }
    }).catch(() => ({}));
    this.sendGaEvent('visit_payment_result_page');
    window.addEventListener('beforeunload', this.beforeUnloadEvent);
  },
  beforeDestroy() {
    this.sendGaEvent('exit_payment_result_page');
    window.removeEventListener('beforeunload', this.beforeUnloadEvent);
  },
  methods: {
    sendGaEvent(eventName) {
      sendGaResultEvent(this.$route.query.type, this.$route.query.key, null, eventName);
    },
    beforeUnloadEvent() {
      this.sendGaEvent('exit_payment_result_page');
    },
  },
};
</script>
<style scoped lang="scss">
.status {
  width: 600px;
  margin: 28px auto 0;
  max-width: 100%;
}
</style>
